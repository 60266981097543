import type { MenuItem } from "primevue/menuitem";

export const useBreadcrumbItems = () => {
  const breadcrumbItems = useState<MenuItem[]>("breadcrumbItems", () => []);
  const setBreadcrumbItems = (items: MenuItem[]) => {
    breadcrumbItems.value = items;
  };
  return {
    breadcrumbItems,
    setBreadcrumbItems,
  };
};
